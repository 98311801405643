import React, { useEffect } from "react";
import { Toast } from "..";

function PeachPayments({ onSuccess = async () => {}, onClose = () => {}, openPg }) {
	const scriptUrl = "https://sandbox-checkout.peachpayments.com/js/checkout.js";
	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => resolve(true);
			script.onerror = () => resolve(false);
			document.body.appendChild(script);
		});
	};

	useEffect(() => {
		loadScript(scriptUrl);
		if (openPg) openPg.current = startPayment;
	}, [openPg]);

	const startPayment = ({ entityId, checkoutId, amount, user, orderId }) => {
		console.log(entityId, checkoutId, amount, user);
		try {
			const checkout = window.Checkout.initiate({
				key: entityId,
				checkoutId,
				events: {
					onCompleted: async (event) => {
						await onSuccess(event, orderId);
						onClose();
					},
					onCancelled: () => {
						onClose();
					},
					onExpired: () => {
						onClose();
						Toast.error("Payment expired, please try again");
					},
				},
				options: { theme: { brand: { primary: "#be3735" }, cards: { background: "#ffffff" } }, enableCancelButton: true },
			});
			checkout.render("#payment-form");
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	return <div id="payment-form" className="h-full min-h-[32rem]"></div>;
}

export default PeachPayments;
