import React from "react";
import { Combobox, Toast, AddGuest } from "../..";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";
import EmployeeInfo from "../../Cards/EmployeeInfo";
import { TbUsers } from "react-icons/tb";

function TravellerSearch({ onSelect = () => {}, placeholder = "Search Users and Guests", label, selected = {}, searchOnly = false, className = "" }) {
	const [AllTravellers, setAllTravellers] = useState([]);
	console.log(AllTravellers);

	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchTravellers(query);
		} else {
			setAllTravellers([]);
		}
	};

	const fetchTravellers = (query) => {
		setLoader(true);
		get(apis.searchTravellers, { search: query, page: 1 }, (r, e) => {
			if (r) {
				setAllTravellers(r.travellers);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="flex items-end w-full gap-2">
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={AllTravellers.map((user) => ({
					...user,
					label: `${user.fName} ${user.lName}`,
					value: user.id,
				}))}
				selected={selected}
				onSelect={onSelect}
				renderItem={(traveller, i) => (
					<li className="!p-0 hover:bg-light flex-center-between">
						<EmployeeInfo className="w-full" key={i} employee={traveller} />
						<div className="px-4">
							{traveller.team && (
								<div className="gap-2 align-center">
									<TbUsers /> <div className="whitespace-nowrap text-label">{traveller.team?.name} Team</div>
								</div>
							)}
							{traveller.isGuest && <div className="text-label">Guest</div>}
						</div>
					</li>
				)}
			/>
			{!searchOnly && <AddGuest btnLabel="+ Add Guest" btnVariant="outlined" />}
		</div>
	);
}

export default TravellerSearch;
