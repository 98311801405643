import React, { useEffect } from "react";
import { Badge, ButtonGroup, DatePicker, EditNationalId, EditPassport, EditableInput, LabelValue, Toast, TravellerSearch, Avatar } from "../../../../../../../../Components";
import { useClientSettings } from "../../../../../../../../Hooks/useClientSettings";
import useUserProfile from "../../../../../../../../Hooks/useUserProfile";
import { validatePhoneNumber } from "../../../../../../../../Utils/utils";
import apis from "../../../../../../../../Utils/apis";
import { get, patch } from "../../../../../../../../Utils/axios";
import moment from "moment";
import { FiMail } from "react-icons/fi";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import { TravellerCard } from "./card";

function Travellers({ airlineCode, mandatoryField = {}, errors = [], resetErrors = () => {}, max, passengers = {}, setPassengers = () => {}, requestId }) {
	const { isEnabled } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const allowTravellersSelection = isEnabled("ORGANIZATION") && checkUserPermission("TA");
	const { profile, reload } = useUserProfile();
	const { id, fName, lName, phone, email, dob, gender, photo, identification } = profile;

	useEffect(() => {
		if (allowTravellersSelection) {
			setPassengers([]);
		} else {
			setPassengers([profile]);
		}
	}, [allowTravellersSelection, profile]);

	useEffect(() => {
		//If booking is done against a request, automatically add the requestor in travellers
		if (requestId) {
			fetchRequestDetails(requestId);
		}
	}, [requestId]);

	const fetchRequestDetails = (requestId) => {
		get(apis.getRequestorDetails, { requestId }, (r, e) => {
			if (r) {
				addTraveller(r);
			}
		});
	};

	const addTraveller = (traveller) => {
		//Check if traveller already exists by id and isGuest
		const index = passengers.findIndex((p) => `${p.id}|${p.isGuest}` === `${traveller.id}|${traveller.isGuest}`);
		if (index >= 0) {
			return Toast.error("You have already selected this traveller once");
		}
		setPassengers((prev) => [...prev, traveller]);
		resetErrors();
	};

	const updateTraveller = (traveller) => {
		console.log(traveller);
		setPassengers((prev) => prev.map((p) => (`${p.id}|${p.isGuest}` === `${traveller.id}|${traveller.isGuest}` ? traveller : p)));
		resetErrors();
	};

	const removeTraveller = (id, isGuest) => {
		console.log(passengers);
		setPassengers((prev) => prev.filter((p) => `${p.id}|${p.isGuest}` !== `${id}|${isGuest}`));
		resetErrors();
	};

	if (allowTravellersSelection) {
		return (
			<>
				{passengers.length < max && <TravellerSearch onSelect={addTraveller} selected={passengers} />}
				{requestId && passengers.length === max && <Badge variant="warning">Travellers were automatically added from the request</Badge>}
				{passengers.map(({ id, isGuest }, i) => (
					<TravellerCard key={i} mandatoryField={mandatoryField} id={id} isGuest={isGuest} removeTraveller={removeTraveller} airlineCode={airlineCode} updateTraveller={updateTraveller} resetErrors={resetErrors} />
				))}

				{errors.length > 0 && (
					<div className="flex gap-2 mt-2">
						{errors.map((error, i) => (
							<Badge key={i} variant="danger">
								{`${i + 1}) ${error}`}
							</Badge>
						))}
					</div>
				)}
			</>
		);
	}

	const onUpdate = async (obj) => {
		if (obj.phone && !(await validatePhoneNumber(obj.phone))) {
			return Toast.error("Please enter valid phone number");
		}
		patch(apis.updateMyProfile, obj, (r, e) => {
			if (r) {
				reload();
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<div className="border rounded-md fade-in-up">
				<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
					<div className="flex-grow gap-2 align-center">
						<Avatar photo={photo} className="w-6 h-6" />
						<h5>
							{fName} {lName}
						</h5>
					</div>
					<div className="gap-2 text-label align-center">
						<FiMail />
						{email}
					</div>
				</div>
				<div className="grid grid-cols-3 gap-4 p-4">
					<LabelValue label="Phone">
						<EditableInput type="number" required onUpdate={(phone) => onUpdate({ phone })} value={phone} label="Phone Number" />
						{phone}
					</LabelValue>
					<LabelValue label="Gender">
						<ButtonGroup
							size="xs"
							selected={{
								label: gender,
								value: gender,
							}}
							onClick={(obj) => onUpdate({ gender: obj.value })}
							options={[
								{ label: "Male", value: "MALE" },
								{ label: "Female", value: "FEMALE" },
							]}
						/>
					</LabelValue>
					{mandatoryField.dobValidation && (
						<LabelValue label="Date of Birth">
							<DatePicker btnClass="!py-1 !text-xs max-w-min" date={moment(dob).valueOf()} setDate={(dob) => onUpdate({ dob: moment(dob).toDate() })} backDateSelection maxDate={moment().subtract(18, "years").valueOf()} />
						</LabelValue>
					)}
					{mandatoryField.passportValidation && (
						<LabelValue label="Passport (Required)">
							<EditPassport reload={reload} identification={identification} photo={photo} />
						</LabelValue>
					)}
					{mandatoryField.nationalityValidation && (
						<LabelValue label="Government ID (Required)">
							<EditNationalId reload={reload} identification={identification} />
						</LabelValue>
					)}
				</div>
			</div>
			{errors.length > 0 && (
				<div className="py-3 space-y-1 text-sm">
					{errors.map((error, i) => (
						<Badge key={i} variant="danger">
							{`${i + 1}) ${error}`}
						</Badge>
					))}
				</div>
			)}
		</>
	);
}

export default Travellers;
