import React, { useState } from "react";
import { Button, ViewBooking } from "../../../../../../Components";
import Eyes from "../../../../../../Assets/Images/emojis/thinking.png";

function BookingPending({ booking }) {
	const [BookingId, setBookingId] = useState();

	return (
		<div className="w-full h-full flex-center bg-light">
			<div className="p-8 border shadow-sm bg-canvas rounded-xl">
				<div className="h-20 flex-center">
					<img src={Eyes} alt="Eyes Emoji" className="w-24 m-auto mb-6" />
				</div>
				<div className="mt-4 text-center">
					<h1>We are still confirming your booking</h1>
					<div className="mt-4 text-label">
						This booking is taking a little longer than expected. <br />
						We are confirming your booking with the airline, you can wait or track this booking in your bookings section.
					</div>
					<Button onClick={() => setBookingId(booking.bookingId)} variant="outlined" className="m-auto mt-8">
						Check Booking
					</Button>
					<ViewBooking id={BookingId} type="flight" onClose={() => setBookingId()} />
				</div>
			</div>
		</div>
	);
}

export default BookingPending;
