import React, { useState } from "react";
import { Badge, Button, Modal, OutOfPolicyBadge } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ selected, flight, fare, familyFare, onSelect, approval }) {
	const { checkUserPermission } = useUserConfig();
	const approvedAmount = approval?.amount;
	const { fareKey, price, fareName, inPolicy, violations, currency, includedServices } = fare;
	const active = selected && selected.selectedFare.fareKey === fareKey && selected.selectedFare.price === price;
	const askApproval = !inPolicy && !checkUserPermission("TA") && (approvedAmount ? price > Number(approvedAmount) : true);

	const [ShowServices, setShowServices] = useState(false);

	if (price > 0)
		return (
			<>
				<div className="flex-1 h-full p-4 space-y-4 text-center flex-center">
					<div>
						{askApproval ? <OutOfPolicyBadge violations={[...violations]} /> : <></>}
						{fareName && <Badge>{fareName}</Badge>}
						<h2>
							{currency} {price}
						</h2>
						{includedServices?.length > 0 && (
							<Button onClick={() => setShowServices(true)} variant="success" className="m-auto mt-2 mb-4 text-xs btn-xs">
								✈️ What's included
							</Button>
						)}
						{onSelect &&
							(active ? (
								<Button onClick={() => onSelect()} className="m-auto mt-2 btn-xs">
									Remove
								</Button>
							) : (
								<Button onClick={() => onSelect(fare)} variant="outlined" className="m-auto mt-2 btn-xs">
									Choose Fare
								</Button>
							))}
					</div>
				</div>
				<Modal open={ShowServices} onClose={() => setShowServices(false)}>
					<div className="p-4">
						<h3 className="mb-4">🚀 What's included in this fare</h3>
						<ul className="space-y-2">
							{includedServices?.map((service) => (
								<Badge key={service}>{service.replace(/[^\x20-\x7Eáéíóúñ]/g, "")}</Badge>
							))}
						</ul>
					</div>
				</Modal>
			</>
		);
	return null;
}

export default Fare;
