import React from "react";
import FlightBookings from "./Flights";

function Bookings() {
	return (
		<div className="p-4 pt-0 pb-24 -mt-2">
			<FlightBookings />
		</div>
	);
}

export default Bookings;
