import React from "react";
import { parseTerminalName, StampTo24hr, StampToDate } from "../../../../../Utils/utils";
import { FaPlane } from "react-icons/fa";
import moment from "moment";

function Flights({ flights, airports = {} }) {
	return (
		<>
			<div className="p-6 pb-2">
				{flights?.map(({ segments }, i) => {
					const departure = segments[0];
					const arrival = segments[segments.length - 1];
					console.log(departure, arrival);
					const duration = moment.utc(arrival.arrivalTime).diff(moment.utc(departure.departureTime), "seconds");
					const hours = Math.floor(duration / 60 / 60);
					const minutes = (duration / 60) % 60;
					const stops = segments.slice(1).map((segment) => segment.departureCode);

					return (
						<div key={i} className="grid grid-cols-3 mb-4">
							<div>
								<h1>{departure.departureCode}</h1>
								<div className="text-label whitespace-nowrap">{StampTo24hr(departure.departureTime)}</div>
								<div className="text-label whitespace-nowrap">{StampToDate(departure.departureTime)}</div>
								<div className="text-label">{airports[departure.departureCode]?.name}</div>
								{departure.departureTerminal && <div className="text-label whitespace-nowrap">{parseTerminalName(departure.departureTerminal)}</div>}
							</div>
							<div className="flex-grow flex-center">
								<div className="w-full">
									<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${departure.airline}.png`} alt={departure.airline} className="w-10 h-10 m-auto rounded-full" />
									<div className="align-center">
										<div className="w-2 h-2 border rounded-full border-dark" />
										<div className="flex-grow border-t border-dotted border-dark" />
										<FaPlane />
										<div className="flex-grow border-t border-dotted border-dark" />
										<div className="w-2 h-2 border rounded-full border-dark" />
									</div>
									<div className="mt-2 text-xs text-center whitespace-nowrap">
										{Math.abs(hours)}hr {Math.abs(minutes)}m {stops.length > 0 && `Via ${stops.join(", ")}`}
									</div>
								</div>
							</div>
							<div className="text-end">
								<h1>{arrival.arrivalCode}</h1>
								<div className="text-label whitespace-nowrap">{StampTo24hr(arrival.arrivalTime)}</div>
								<div className="text-label whitespace-nowrap">{StampToDate(arrival.arrivalTime)}</div>
								<div className="text-label">{airports[arrival.arrivalCode]?.name}</div>
								{arrival.arrivalTerminal && <div className="text-label whitespace-nowrap">{parseTerminalName(arrival.arrivalTerminal)}</div>}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Flights;
