import React, { useEffect, useState } from "react";
import Processing from "./processing";
import Success from "./success";
import { apis, post } from "../../../../../../Utils/axios";
import { useLocation } from "react-router-dom";
import { Toast } from "../../../../../../Components";
import BookingFailed from "./failed";
import BookingPending from "./pending";

function ConfirmBooking() {
	const LOCATION = useLocation();
	const { selectedFlightOptionKey, logo, name, code, approvalId, requestId, customFields, costCenterId, passengers, paymentMode } = LOCATION.state || {};

	const [Booking, setBooking] = useState();

	useEffect(() => {
		if (selectedFlightOptionKey) bookFlight();
	}, []);

	const bookFlight = () => {
		setBooking();
		post(apis.bookFlight, { requestId, approvalId, customFields, costCenterId, passengers, paymentMode, selectedFlightOptionKey }, (r, e) => {
			//clear the location state
			window.history.replaceState({}, "", window.location.pathname);
			if (r?.booking) {
				setBooking(r.booking);
				//Clear the location state
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	if (Booking?.status === "BOOKED") return <Success booking={Booking} />;
	if (Booking?.status === "FAILED") return <BookingFailed booking={Booking} />;
	if (Booking?.status === "PENDING") return <BookingPending booking={Booking} />;
	return <Processing flight={{ logo, name, code }} />;
}

export default ConfirmBooking;
