import apis from "../../Utils/apis";
import { get } from "../../Utils/axios";
import BellIcon from "./icons/bell.png";

const ALERTS = [
	{
		icon: BellIcon,
		title: "Low wallet balance",
		description: "You have low wallet balance. Please top up.",
		check: ({ isAdmin }, cb) => {
			if (isAdmin) {
				get(apis.getWalletBalance, {}, (r, e) => {
					if (r) {
						if (r.balance < 100) {
							cb(true);
						} else {
							cb(false);
						}
					}
				});
			}
		},
	},
];

export default ALERTS;
