import axios from "axios";
import apis from "./apis";
import { Toast } from "../Components";

const ngrokUrl = localStorage.getItem("ngrok");

const getServerOrigin = () => {
	if (ngrokUrl) {
		//ngrok Tunnel
		return ngrokUrl;
	} else if (origin.includes("localhost")) {
		//Local Environment
		return "http://localhost:8080";
	} else if (origin.includes("192.168")) {
		//Local Environment
		return `http:${origin.split(":")[1]}:8080`;
	} else {
		//Staging
		return "https://corpuat.twingstech.com";
	}
};

let ORIGIN = getServerOrigin();

const getOrigin = (url) => {
	if (url.startsWith("/")) {
		return ORIGIN + url;
	} else if (url.startsWith("http")) {
		return url;
	} else {
		return ORIGIN + "/" + url;
	}
};

const getToken = () => {
	return localStorage.getItem("token");
};

//Request interceptor
axios.interceptors.request.use(
	function (config) {
		const token = getToken();
		if (token) {
			//If header is not set default header json will be used
			if (!config.headers["Content-Type"]) {
				config.headers["Content-Type"] = `application/json`;
			}
			config.headers.Authorization = `Bearer ${token}`;
		}
		if (ngrokUrl) {
			//To skip ngrok warning
			config.headers["ngrok-skip-browser-warning"] = "skip";
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

//Response interceptor
axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			//Force Logout when token expired
			localStorage.removeItem("token");
			localStorage.removeItem("configuration");
			window.location.href = "/login";
		}
		return Promise.reject(error);
	}
);

const post = (url, data, cb) => {
	url = getOrigin(url);
	axios
		.post(url, data)
		.then((res) => {
			cb(res.data);
		})
		.catch((err) => {
			console.log(err);
			cb(null, err);
		});
};

const asyncPost = async (url, data) => {
	url = getOrigin(url);
	const res = await axios.post(url, data);
	return res.data;
};

const get = (url, params, cb) => {
	url = getOrigin(url);
	axios
		.get(url, { params })
		.then((res) => {
			cb(res.data);
		})
		.catch((err) => {
			cb(null, err);
		});
};

const patch = (url, data, cb) => {
	url = getOrigin(url);
	axios
		.patch(url, data)
		.then((res) => {
			cb(res.data);
		})
		.catch((err) => {
			cb(null, err);
		});
};

const deleteRequest = (url, data, cb) => {
	url = getOrigin(url);
	axios
		.delete(url, { data })
		.then((res) => {
			cb(res.data);
		})
		.catch((err) => {
			cb(null, err);
		});
};

const uploadFile = async (file, cb) => {
	if (file) {
		const dismissToast = Toast.loading("Uploading...");
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file.name);
		axios
			.post(getOrigin(`/upload/file`), formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					authorization: `Bearer ${getToken()}`,
				},
			})
			.then((res) => {
				dismissToast();
				cb(res.data);
			})
			.catch((err) => {
				dismissToast();
				cb(undefined, err);
			});
	}
};

const uploadFileToUrl = async (file, url, data, cb) => {
	if (file) {
		const dismissToast = Toast.loading("Uploading...");
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file.name);
		//Append data to form
		Object.keys(data).forEach((key) => {
			formData.append(key, data[key]);
		});
		axios
			.post(getOrigin(url), formData, {
				headers: { "Content-Type": "multipart/form-data" },
				authorization: `Bearer ${getToken()}`,
			})
			.then((res) => {
				dismissToast();
				cb(res.data);
			})
			.catch((err) => {
				dismissToast();
				cb(undefined, err);
			});
	}
};

export { post, get, patch, deleteRequest, uploadFile, uploadFileToUrl, apis, getServerOrigin, asyncPost };
