import React from "react";
import { Button, Input, Modal, FileInput, Toast, Form, TimePicker, DatePicker, Checkbox, Chips } from "../..";
import { useState } from "react";
import { mergeDateAndTime } from "../../../Utils/utils";
import { apis, post } from "../../../Utils/axios";
import { FiMapPin, FiUpload } from "react-icons/fi";
import { FaRupeeSign } from "react-icons/fa";
import PlaceSearch from "../Inputs/PlaceSearch";

function ImportCabBooking({ requestId, onReload = () => {}, btnLabel = "Import", btnClass = "", btnVariant = "primary" }) {
	const [ImportCab, setImportCab] = useState();
	const initial = {
		time: "",
		date: "",
		amount: "",
		pickup: {},
		drop: {},
		variant: {
			label: "Hatchback",
			value: "hatchback",
		},
		forAirport: false,
		bookingId: "",
		vehicleNumber: "",
		vehicleModel: "",
		ticket: "",
		createExpense: true,
		requestId,
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.date) {
			return Toast.error("Please select pickup date");
		}
		if (!Values.time) {
			return Toast.error("Please select pickup time");
		}
		if (!Values.pickup?.value) {
			return Toast.error("Please select pickup location");
		}
		if (!Values.drop?.value) {
			return Toast.error("Please select drop location");
		}
		if (!Values.variant?.value) {
			return Toast.error("Please select cab variant");
		}
		setLoader(true);
		post(
			apis.importCabBooking,
			{
				...Values,
				pickupTime: mergeDateAndTime(Values.date, Values.time),
				variant: Values.variant.value,
				pickup: Values.pickup.value,
				drop: Values.drop.value,
			},
			(r, e) => {
				if (r) {
					setLoader();
					setImportCab();
					setValues(initial);
					onReload();
					Toast.success("Cab booking imported");
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setImportCab(true)} variant={btnVariant} className={btnClass}>
				<FiUpload className="mr-2" />
				{btnLabel}
			</Button>
			<Modal
				open={ImportCab}
				onClose={() => {
					setImportCab();
					setValues(initial);
				}}
			>
				<div className="p-6">
					<h1 className="gap-2 align-center">
						<FiUpload />
						Import Cab Booking
					</h1>
					<div className="mt-2 text-label">You can import bookings done from outside and use it in Travel Wings</div>
					<Form onSubmit={onSubmit} className="flex flex-col mt-4 gap-y-4">
						<div className="grid grid-cols-2 gap-4">
							<PlaceSearch selected={Values.pickup} label="Pickup" onSelect={(pickup) => setValues((prev) => ({ ...prev, pickup }))} placeholder="Pickup Location" icon={<FiMapPin />} />
							<PlaceSearch selected={Values.drop} label="Drop" onSelect={(drop) => setValues((prev) => ({ ...prev, drop }))} placeholder="Drop Location" icon={<FiMapPin />} />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="Pickup Date" setDate={(date) => setValues((prev) => ({ ...prev, date }))} date={Values.date} />
							<TimePicker label="Pickup Time" onChange={(time) => setValues((prev) => ({ ...prev, time }))} value={Values.time} required />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input required value={Values.bookingId} onChange={(bookingId) => setValues((prev) => ({ ...prev, bookingId }))} label="Booking ID" placeholder="Booking ID" />
							<Input min={0} icon={<FaRupeeSign />} type="number" required value={Values.amount} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} label="Amount" placeholder="Amount" />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input value={Values.vehicleNumber} onChange={(vehicleNumber) => setValues((prev) => ({ ...prev, vehicleNumber }))} label="Vehicle Number (Optional)" placeholder="Vehicle Number" />
							<Input value={Values.vehicleModel} onChange={(vehicleModel) => setValues((prev) => ({ ...prev, vehicleModel }))} label="Vehicle Model (Optional)" placeholder="Vehicle Model" />
						</div>
						<div>
							<label>Cab Type</label>
							<Chips
								selected={Values.variant}
								onSelect={(variant) => setValues((prev) => ({ ...prev, variant }))}
								className="mt-2"
								options={[
									{
										label: "Hatchback",
										value: "hatchback",
									},
									{
										label: "Sedan",
										value: "sedan",
									},
									{
										label: "SUV",
										value: "suv",
									},
								]}
							/>
						</div>
						<div className="grid grid-cols-2 gap-4">
							<FileInput onDelete={() => setValues((prev) => ({ ...prev, ticket: "" }))} file={Values.ticket} label="Receipt File" onUpload={(obj) => setValues((prev) => ({ ...prev, ticket: obj.file.key }))} />
						</div>
						<Checkbox checked={Values.createExpense} onChange={(createExpense) => setValues((prev) => ({ ...prev, createExpense }))} label="Create an expense for this booking" />
						<Button loading={Loader} type="submit" className="w-full mt-4">
							Import
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ImportCabBooking;
