import Logo from "Components/Logo";
import { useClientSettings } from "Hooks/useClientSettings";
import { useUserConfig } from "Hooks/useUserConfig";
import moment from "moment";
import AddMoney from "Pages/App/Admin/Wallet/addMoney";

export const Billing = ({ children }) => {
	const { userConfig } = useUserConfig();
	const isAdmin = userConfig.isAdmin;
	const { latestBill, currency } = useClientSettings();
	if (!latestBill) return children;
	const daysRemaining = moment(latestBill.dueDate).diff(moment(), "days");
	console.log(daysRemaining);
	const billDue = daysRemaining <= 0 && Number(latestBill.paidAmount) < Number(latestBill.dueAmount);
	if (!billDue) return children;

	return (
		<div id="main-container" className="relative flex flex-col items-center justify-center flex-1 space-y-12 overflow-x-visible overflow-y-auto">
			<Logo short />
			<div className="flex flex-col items-center justify-center gap-4">
				<div className="flex items-center justify-center gap-2">
					<h1 className="flex items-center gap-2 text-3xl text-primary">
						<span>Subscription Expired</span>
					</h1>
				</div>
				<p className="max-w-md text-center text-secondary">Your subscription has expired on {moment(latestBill.dueDate).format("DD MMM YYYY")}. Please recharge to continue using your account</p>
				{isAdmin && (
					<p className="p-2 px-8 text-center border rounded-full">
						Your recharge amount is <span className="font-bold">USD {Number(latestBill.dueAmount).toFixed(2)}</span>
					</p>
				)}
			</div>
			{isAdmin && <AddMoney currency={currency} onReload={window.location.reload} />}
		</div>
	);
};
