import React from "react";
import { Button, Input, Modal, FileInput, Toast, Form, DatePicker, Checkbox, PlaceSearch } from "../../../Components";
import { useState } from "react";
import { apis, post } from "../../../Utils/axios";
import { FiUpload } from "react-icons/fi";
import { FaLocationArrow, FaRupeeSign } from "react-icons/fa";

function ImportStayBooking({ requestId, onReload = () => {}, btnLabel = "Import", btnClass = "", btnVariant = "primary" }) {
	const [ImportStay, setImportStay] = useState();
	const initial = {
		amount: "",
		checkIn: "",
		checkOut: "",
		address: {},
		room: "",
		ticket: "",
		bookingId: "",
		name: "",
		requestId,
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.address) {
			return Toast.error("Please search hotel address");
		}
		if (!Values.checkIn) {
			return Toast.error("Please select checkIn date");
		}
		if (!Values.checkOut) {
			return Toast.error("Please select checkOut date");
		}
		setLoader(true);
		post(
			apis.importStayBooking,
			{
				...Values,
				checkIn: Values.checkIn,
				checkOut: Values.checkOut,
				address: Values.address.value,
			},
			(r, e) => {
				if (r) {
					setLoader();
					setImportStay();
					setValues(initial);
					Toast.success("Stay booking imported");
					onReload();
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setImportStay(true)} variant={btnVariant} className={btnClass}>
				<FiUpload className="mr-2" />
				{btnLabel}
			</Button>
			<Modal
				open={ImportStay}
				onClose={() => {
					setImportStay();
					setValues(initial);
				}}
			>
				<div className="p-6">
					<h1 className="gap-2 align-center">
						<FiUpload />
						Import Stay Booking
					</h1>
					<div className="mt-2 text-label">You can import bookings done from outside and use it in Travel Wings</div>
					<Form onSubmit={onSubmit} className="flex flex-col mt-4 gap-y-4">
						<PlaceSearch selected={Values.address} label="Address" onSelect={(address) => setValues((prev) => ({ ...prev, address }))} placeholder="Search Hotel Address" icon={<FaLocationArrow />} />
						<Input required value={Values.name} onChange={(name) => setValues((prev) => ({ ...prev, name }))} label="Hotel name" placeholder="Hotel name" />
						<Input required value={Values.room} onChange={(room) => setValues((prev) => ({ ...prev, room }))} label="Room title" placeholder="Room title" />
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="CheckIn Date" setDate={(checkIn) => setValues((prev) => ({ ...prev, checkIn }))} date={Values.checkIn} />
							<DatePicker label="CheckOut Date" setDate={(checkOut) => setValues((prev) => ({ ...prev, checkOut }))} date={Values.checkOut} />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input required value={Values.bookingId} onChange={(bookingId) => setValues((prev) => ({ ...prev, bookingId }))} label="Booking ID" placeholder="Booking ID" />
							<Input min={0} icon={<FaRupeeSign />} type="number" required value={Values.amount} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} label="Amount" placeholder="Amount" />
						</div>
						<FileInput onDelete={() => setValues((prev) => ({ ...prev, ticket: "" }))} file={Values.ticket} label="Ticket File" onUpload={(obj) => setValues((prev) => ({ ...prev, ticket: obj.file.key }))} />
						<Checkbox checked={Values.createExpense} onChange={(createExpense) => setValues((prev) => ({ ...prev, createExpense }))} label="Create an expense for this booking" />
						<Button loading={Loader} type="submit" className="w-full mt-4">
							Import
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ImportStayBooking;
