import React from "react";
import GreenGlass from "../../../../Assets/Images/avinash.jpg";
import { FaBuilding, FaChevronRight, FaEdit, FaUsers } from "react-icons/fa";
import useUserProfile from "../../../../Hooks/useUserProfile";
import { apis, patch, post } from "../../../../Utils/axios";
import { useClientSettings } from "../../../../Hooks/useClientSettings";
import { FileInput, Toast } from "../../../../Components";
import { useUserConfig } from "../../../../Hooks/useUserConfig";

function CoverPhoto() {
	const { userConfig } = useUserConfig();
	const isAdmin = userConfig.isAdmin;
	const { cover, reload, isEnabled } = useClientSettings();
	const { profile } = useUserProfile();

	const onUpdate = ({ file }) => {
		patch(
			apis.updateClientSettings,
			{
				settings: {
					cover: file.key,
				},
			},
			(r, e) => {
				if (r) {
					reload();
					Toast.success(`Cover Photo updated`);
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<div className="relative h-40 -mb-10">
			<img src={cover || GreenGlass} alt="Company cover" className="absolute top-0 left-0 right-0 object-cover w-full h-full -z-10" />
			<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to bottom, rgba(255,255,255,0) 20%,rgba(255,255,255,1))" }} />
			<div className="h-40 p-4">
				<div className="flex-center-between">
					{isAdmin ? (
						<FileInput file={cover} onUpload={onUpdate}>
							<div className="bg-canvas frosted cursor-pointer rounded-full p-2.5 align-center text-xs gap-2">
								<FaEdit />
							</div>
						</FileInput>
					) : (
						<div />
					)}
					{isEnabled("ORGANIZATION") && (
						<div className="p-2 rounded-full bg-canvas frosted">
							<div className="gap-2 text-xs align-center">
								<FaUsers />
								{profile.team?.name}
								<FaChevronRight />
								<FaBuilding />
								{profile.office?.name}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CoverPhoto;
