import React, { useRef } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import { Button, CopyValue, Toast } from "../Library";
import moment from "moment";
import { parseTerminalName, StampTo24hr, StampToDate } from "../../Utils/utils";
import { FiDownload } from "react-icons/fi";
import Logo from "Components/Logo";

function FlightTicket({ booking = {} }) {
	const TICKET = useRef(null);

	const downloadPDF = () => {
		savePDF(TICKET.current, {
			paperSize: "auto",
			margin: 50,
			fileName: `Flight Ticket`,
		});
		Toast.success("Ticket Downloaded");
	};

	console.log(booking);

	const { flights, travellers, amount, date, maker, pnr, bookRefNo } = booking;

	return (
		<>
			<div ref={TICKET} className="max-w-[900px] min-w-[700px] m-auto text-sm bg-light rounded-3xl">
				<div className="p-12">
					<div className="flex-center-between">
						<Logo className="mb-6" />
						<Button onClick={downloadPDF} variant="outlined" className="gap-2 btn-sm">
							<FiDownload /> Download as PDF
						</Button>
					</div>
					<div className="p-6 text-white rounded-lg bg-dark flex-center-between">
						<div className="text-xl">Flight Booking</div>
						<div>
							<div className="text-lg font-bold">PNR: {pnr}</div>
						</div>
					</div>
					<div className="gap-6 p-6 mt-4 bg-white border rounded-lg">
						<div className="mb-6 text-lg font-bold">Booking Details</div>
						<div className="grid grid-cols-3 gap-4">
							<div>
								<div className="mb-2 font-bold">Booked On</div>
								<div>{moment(date).format("DD MMM YYYY")}</div>
							</div>
							<div>
								<div className="mb-2 font-bold">Booked by</div>
								<div>{maker?.email}</div>
							</div>
							<div className="text-end">
								<div className="mb-2 font-bold">Amount</div>
								<div>Rs. {amount}</div>
							</div>
							<div className="col-span-3">
								<div className="mb-2 font-bold">Booking Reference No.</div>
								<CopyValue>{bookRefNo}</CopyValue>
							</div>
						</div>
					</div>
					<div className="p-6 mt-6 bg-white border rounded-lg">
						<div className="text-lg font-bold">Flight Details</div>
						{flights?.map(({ segments }) => {
							return segments.map((segment, j) => {
								const { airline, flightNumber, departureCode, departureAirport, departureTime, arrivalCode, arrivalAirport, arrivalTime, departureTerminal, arrivalTerminal } = segment;
								const hours = moment.utc(departureTime).diff(moment.utc(arrivalTime), "hours");
								const minutes = moment.utc(departureTime).diff(moment.utc(arrivalTime), "minutes") - hours * 60;
								return (
									<div key={j} className="flex items-start justify-between gap-6 mt-6">
										<div>
											<div className="font-bold">
												{airline}-{flightNumber}
											</div>
											<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${airline}.png`} alt="Airline" className="w-10 h-10 mt-2 rounded-full" />
										</div>
										<div className="text-xs text-end">
											<div className="mb-2 text-2xl font-bold">{departureCode}</div>
											<div>
												{StampTo24hr(departureTime)}, {StampToDate(departureTime)}
											</div>
											<div>
												{parseTerminalName(departureTerminal)}
												<br />
												{departureAirport}
											</div>
										</div>
										<div className="flex-grow flex-center min-w-sm">
											<div className="w-full mt-1 align-center">
												<div className="w-2 h-2 border rounded-full bg-light" />
												<div className="flex-grow border border-dashed" />
												<div className="px-2 py-1 text-xs text-center border rounded-full bg-light whitespace-nowrap">
													{Math.abs(hours)}hr {Math.abs(minutes)}m
												</div>
												<div className="flex-grow border border-dashed" />
												<div className="w-2 h-2 border rounded-full bg-light" />
											</div>
										</div>
										<div className="text-xs">
											<div className="mb-2 text-2xl font-bold">{arrivalCode}</div>
											<div>
												{StampTo24hr(arrivalTime)}, {StampToDate(arrivalTime)}
											</div>
											<div>
												{parseTerminalName(arrivalTerminal)}
												<br />
												{arrivalAirport}
											</div>
										</div>
									</div>
								);
							});
						})}
					</div>
					<div className="p-6 mt-5 bg-white border rounded-lg">
						<div className="text-lg font-bold">{travellers?.length} Traveller(s)</div>
						<table className="w-full mt-6">
							<tbody>
								<tr className="font-bold">
									<td>NAME</td>
									<td>GENDER</td>
									<td>EMAIL</td>
									<td>PHONE</td>
								</tr>
								{travellers?.map((traveller, i) => {
									const { passenger, guest } = traveller;
									const { fName, lName, gender, email, phone, phoneCode } = passenger || guest;
									return (
										<tr key={i}>
											<td>
												{fName} {lName}
											</td>
											<td>{gender}</td>
											<td>{email}</td>
											<td>
												{phoneCode}-{phone}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="mt-6 text-xs text-secondary">
						<b>Note:</b> The respective airlines will issue a separate Ticket with all the final details.
						<br />
						<b>For support</b> reach out to +91 1234567890, info@projectpay.in
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightTicket;
