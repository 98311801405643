const AUTH_ACTIONS = {
	login: "LOGIN",
	logout: "LOGOUT",
	config: "CONFIG",
	modules: "MODULES",
};

let initialState = {
	authenticated: localStorage.getItem("token") ? true : false,
	config: { loading: true },
	modules: { loading: true },
	lastLogin: localStorage.getItem("lastLogin"),
	token: localStorage.getItem("token"),
};

const authorization = (state = initialState, { data, type }) => {
	if (type === AUTH_ACTIONS.login) {
		return {
			...state,
			authenticated: true,
			token: data.token,
		};
	} else if (type === AUTH_ACTIONS.logout) {
		return initialState;
	} else {
		return state;
	}
};

export { AUTH_ACTIONS, authorization };
