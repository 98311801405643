import React, { useState } from "react";
import { Button, Container, CopyValue, Modal, Toast } from "../../../../../Components";
import Buddy from "../../../../../Assets/Images/buddy.png";
import { apis, get } from "../../../../../Utils/axios";

function TemporaryPassword() {
	const [TempPassword, setTempPassword] = useState();
	const [Loader, setLoader] = useState();

	const getTempPassword = () => {
		setLoader(true);
		get(apis.getTempPassword, {}, (r, e) => {
			if (r) {
				setLoader();
				setTempPassword(r.tempPassword?.uuid);
			} else if (e) {
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	return (
		<>
			<Container variant="outlined" className="flex flex-col justify-between">
				<h4 className="mb-4">Temporary Password</h4>
				<div className="align-center">
					<img src={Buddy} alt="Buddy" className="w-32 mb-4 mr-4 rounded-lg" />
					<div className="mb-4 text-sm align-center">Want someone to access your account for a short period of time? Generate a temporary password for them to use.</div>
				</div>
				<div className="flex gap-x-4">
					<Button loading={Loader} onClick={getTempPassword} variant="success">
						Generate Password
					</Button>
				</div>
			</Container>
			<Modal open={TempPassword} onClose={() => setTempPassword()}>
				<div className="p-8 text-center">
					<h1>Temporary Password</h1>
					<div className="mt-2 text-label">Remember this temporary password is valid only for 24 hours and can be used only once.</div>
					<CopyValue className="mt-4 text-sm flex-center">{TempPassword}</CopyValue>
				</div>
			</Modal>
		</>
	);
}

export default TemporaryPassword;
