import { Logo } from "Components";
import React from "react";

function Processing({ flight }) {
	if (!flight) {
		return null;
	}

	const { logo, name, code } = flight || {};

	return (
		<>
			<div className="w-full h-full flex-center">
				<div className="flex flex-col gap-y-8">
					<div className="text-center">
						<img src={logo} alt={name} className="w-12 h-12 m-auto mb-6 rounded-full shadow-xl" />
						<div className="mt-2 text-xs text-secondary whitespace-nowrap">
							{name}&nbsp;&nbsp;{code}
						</div>
					</div>
					<span className="max-w-xs m-auto payment-loader"></span>
					<div className="text-center">
						<h4>We are confirming your booking</h4>
						<div className="text-label">Please wait while we confirm your booking. This will only take a minute.</div>
					</div>
					<Logo className="m-auto mt-20" />
				</div>
			</div>
		</>
	);
}

export default Processing;
