import React from "react";
import { TbCheck, TbX } from "react-icons/tb";

function RechargePage() {
	const queryParams = new URLSearchParams(window.location.search);
	const recharge = queryParams.get("recharge");

	if (recharge === "success") {
		return (
			<div className="flex items-center justify-center w-full h-full p-12 overflow-y-auto bg-white">
				<div className="space-y-12">
					<div className="flex items-center justify-center p-6 m-auto text-5xl rounded-full bg-success max-w-min">
						<TbCheck />
					</div>
					<div className="text-center">
						<h1 className="text-4xl font-bold">Done</h1>
						<p className="mt-2 opacity-50">You can close this window now</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="flex items-center justify-center w-full h-full p-12 overflow-y-auto bg-white">
			<div className="space-y-12">
				<div className="flex items-center justify-center p-6 m-auto text-5xl rounded-full bg-danger max-w-min">
					<TbX />
				</div>
				<div className="text-center">
					<h1 className="text-4xl font-bold">Payment Failed</h1>
					<p className="mt-2 opacity-50">Your payment was not successful, do not worry you have not been charged</p>
				</div>
			</div>
		</div>
	);
}

export default RechargePage;
