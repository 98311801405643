import React, { useState } from "react";
import { Badge, Button, Modal, OutOfPolicyBadge } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ selected, flight, fare, cheapestFare, highestFare, onSelect, approval, familyFare }) {
	const { checkUserPermission } = useUserConfig();
	const approvedAmount = approval?.amount;
	const { fareKey, price, refundable, inPolicy, violations, currency, includedServices, bundledServiceName } = fare;
	const active = selected && selected.selectedFare.fareKey === fareKey;
	const askApproval = !inPolicy && !checkUserPermission("TA") && (approvedAmount ? price > Number(approvedAmount) : true);

	const [ShowServices, setShowServices] = useState(false);
	console.log(includedServices);

	if (price > 0)
		return (
			<>
				<div className="flex-1 p-4 flex-center">
					<div className="p-2 space-y-4 text-center">
						{askApproval ? <OutOfPolicyBadge violations={[...violations, ...flight.violations]} /> : <></>}
						<h2>
							{currency} {price}
						</h2>
						{bundledServiceName && <p className="text-xs opacity-70">{bundledServiceName}</p>}
						{includedServices?.length > 0 && (
							<Button onClick={() => setShowServices(true)} variant="success" className="m-auto mt-2 mb-4 text-xs btn-xs">
								✈️ What's included
							</Button>
						)}
						{onSelect &&
							(active ? (
								<Button onClick={() => onSelect()} className="w-full mt-2 btn-xs">
									Remove
								</Button>
							) : (
								<Button onClick={() => onSelect(fare)} variant="outlined" className="w-full mt-2 btn-xs">
									Choose
								</Button>
							))}
					</div>
				</div>
				<Modal open={ShowServices} onClose={() => setShowServices(false)}>
					<div className="p-4">
						<h3 className="mb-4">🚀 What's included in this fare</h3>
						<ul className="space-y-2">
							{includedServices?.map((service) => (
								<Badge key={service}>{service.replace(/[^\x20-\x7Eáéíóúñ]/g, "")}</Badge>
							))}
						</ul>
					</div>
				</Modal>
			</>
		);
	return null;
}

export default Fare;
