import React, { useState } from "react";
import { Button, ViewBooking } from "../../../../../../Components";
import { useNavigate } from "react-router-dom";
import Eyes from "../../../../../../Assets/Images/emojis/eyes.png";

function BookingFailed({ booking }) {
	const [BookingId, setBookingId] = useState();

	const NAVIGATE = useNavigate();
	return (
		<div className="w-full h-full flex-center bg-light">
			<div className="p-8 border shadow-sm bg-canvas rounded-xl">
				<div className="h-20 flex-center">
					<img src={Eyes} alt="Eyes Emoji" className="w-24 m-auto mb-6" />
				</div>
				<div className="mt-4 text-center">
					<h1>Booking Failed</h1>
					<div className="mt-4 text-label">
						We are facing problems in booking this flight you can go back <br />
						and try again, or choose a different flight. If money was deducted it will refunded automatically.
					</div>
					<div className="flex items-center justify-center gap-4 mt-6">
						<Button onClick={() => NAVIGATE(`/app/travel`)}>Go Back</Button>
						<Button onClick={() => setBookingId(booking.bookingId)} variant="outlined">
							View Booking
						</Button>
					</div>
					<ViewBooking id={BookingId} type="flight" onClose={() => setBookingId()} />
				</div>
			</div>
		</div>
	);
}

export default BookingFailed;
