import React, { useState } from "react";
import { ViewBooking } from "../../";
import { Button } from "../..";

function OpenBooking({ type, flightId, flight, stayId, cabId, status }) {
	const [ShowBooking, setShowBooking] = useState();

	let bookingId;
	if (type === "flight") bookingId = flight?.bookingId;
	if (type === "stay") bookingId = stayId;

	if (status === "booked")
		return (
			<>
				<div className="p-4 rounded-lg shadow-sm flex-center-between bg-canvas">
					<div className="gap-4 align-center">
						<svg className="w-6 h-6 checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
							<circle className="checkmark_circle" cx={26} cy={26} r={25} fill="none" /> <path className="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
						</svg>
						<h5>Booking made for this approval</h5>
					</div>
					<Button variant="primary" onClick={() => setShowBooking(true)} className="btn-sm">
						View Booking
					</Button>
				</div>
				{ShowBooking && <ViewBooking type={type} id={bookingId} onClose={() => setShowBooking(false)} />}
			</>
		);

	return null;
}

export default OpenBooking;
