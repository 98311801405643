import React, { useState } from "react";
import { FlightSearch, Modal } from "../../../../../Components";
import { Chips } from "../../../../../Components";
import CloudsVideo from "../../../../../Assets/Videos/clouds.mp4";
import { FiArrowRight, FiZap } from "react-icons/fi";
import { CHECKIN_URLS } from "../../../../../Utils/constants";

function FlightHome() {
	const QUICK_OPTIONS = [
		{
			label: "Delhi to Mumbai",
			value: {
				from: {
					airportName: "New Delhi (DEL)",
					cityName: "New Delhi",
					airportCode: "DEL",
					country: "India",
				},
				to: {
					airportName: "Mumbai (BOM)",
					cityName: "Mumbai",
					airportCode: "BOM",
					country: "India",
				},
			},
		},
		{
			label: "New Delhi (DEL) to Bangalore (BLR)",
			value: {
				from: {
					airportName: "New Delhi (DEL)",
					cityName: "New Delhi",
					airportCode: "DEL",
					country: "India",
				},
				to: {
					airportName: "Bangalore (BLR)",
					cityName: "Bangalore",
					airportCode: "BLR",
					country: "India",
				},
			},
		},
		{
			label: "Bangalore (BLR) to Mumbai (BOM)",
			value: {
				from: {
					airportName: "Bangalore (BLR)",
					cityName: "Bangalore",
					airportCode: "BLR",
					country: "India",
				},
				to: {
					airportName: "Mumbai (BOM)",
					cityName: "Mumbai",
					airportCode: "BOM",
					country: "India",
				},
			},
		},
	];

	const [ShowLinks, setShowLinks] = useState(false);
	const [QuickSelection, setQuickSelection] = useState({
		from: {},
		to: {},
	});

	return (
		<>
			<div className="p-4">
				<FlightSearch from={QuickSelection.from} to={QuickSelection.to} />
				<div className="mt-6 align-center">
					<div className="mr-2 text-sm align-center whitespace-nowrap">
						<FiZap className="mr-1" />
						Trending Searches
					</div>
					<Chips options={QUICK_OPTIONS} onSelect={(option) => setQuickSelection(option.value)} />
				</div>

				<div className="mt-8">
					<h3>Travelling today</h3>
					<div className="mt-4 md:grid md:grid-cols-4 gap-x-4">
						<div onClick={() => setShowLinks(true)} className="p-6 border shadow-sm cursor-pointer rounded-xl bg-canvas hover:bg-light">
							<h1 className="text-2xl font-normal">Web Check-in</h1>
							<div className="max-w-xl mt-3 text-sm text-secondary">Easily go to airline website for check-in</div>
							<FiArrowRight className="mt-2 text-2xl" />
						</div>
					</div>
				</div>
			</div>
			<video className="absolute bottom-0 left-0 object-cover w-full h-full -z-10" autoPlay muted loop>
				<source src={CloudsVideo} type="video/mp4" />
			</video>
			<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to bottom, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%)" }} />
			<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 20%)" }} />
			<div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full -z-10" style={{ background: "linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 20%)" }} />

			<Modal open={ShowLinks} onClose={() => setShowLinks(false)}>
				<div className="p-8">
					<h3 className="text-center">Web Check-In</h3>
					<div className="grid grid-cols-3 gap-4 mt-4">
						{CHECKIN_URLS.map((airline, i) => (
							<div onClick={() => window.open(airline.url, "_blank")} key={i} className="p-4 border rounded-lg cursor-pointer flex-center hover:bg-light">
								<div>
									<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${airline.code}.png`} alt={airline.label} className="w-10 h-10 m-auto rounded-full" />
									<div className="mt-4 text-xs text-center">{airline.label}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</Modal>
		</>
	);
}

export default FlightHome;
