import React, { useState } from "react";
import Travel from "../../Assets/Videos/cycle.mp4";
import { Form, Input, ErrorMsg, Button, Logo, Toast, Select } from "../../Components";
import { apis, post } from "../../Utils/axios";
import { useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { validatePhoneNumber } from "Utils/utils";
import { COUNTRIES } from "Utils/constants";
import SubscriptionPlans from "./Subscription";

function Signup() {
	const NAVIGATE = useNavigate();
	const [UserId, setUserId] = useState();
	const [Values, setValues] = useState({ fName: "", lName: "", email: "", phone: "", company: "", currency: "", phoneCode: {}, country: {} });

	const [ShowPlans, setShowPlans] = useState(false);
	const [CheckInbox, setCheckInbox] = useState(false);
	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();

	const validateForm = async (e) => {
		e.preventDefault();
		if (!Values.country) {
			return Toast.error("Please select a country");
		}
		setError();
		setLoader(true);
		if (!(await validatePhoneNumber(Values.phone, Values.phoneCode.value))) {
			setLoader();
			return Toast.error(`Please enter a valid ${Values.country.label} phone number`);
		}
		setLoader();
		setShowPlans(true);
	};

	const onSubmit = async (plan) => {
		setError();
		setLoader(true);
		post(
			apis.signupUsingEmail,
			{
				...Values,
				phoneCode: Values.phoneCode.value,
				country: Values.country.value,
				currency: Values.country.currency,
				plan,
			},
			(r, e) => {
				setLoader();
				if (r) {
					setCheckInbox(true);
					setUserId(r.userId);
				} else if (e) {
					setError(e.response?.data?.error);
					setShowPlans(false);
					Toast.handleError(e);
				}
			}
		);
	};

	const resendMail = (e) => {
		setError();
		setLoader(true);
		post(apis.resendSignupEmail, { userId: UserId }, (r, e) => {
			if (r) {
				Toast.success("Signup email sent");
				setLoader();
			} else if (e) {
				Toast.handleError(e);
				setLoader();
			}
		});
	};

	if (ShowPlans && !CheckInbox) {
		return <SubscriptionPlans error={Error} onCancel={() => setShowPlans(false)} loading={Loader} onSubmit={onSubmit} />;
	}

	return (
		<div className={`grid md:grid-cols-2 p-6 h-full`}>
			<div className="relative items-end hidden w-full h-full overflow-hidden text-white bg-center bg-no-repeat bg-cover border-4 md:flex rounded-2xl bg-light border-dark">
				<video muted autoPlay loop className="object-cover w-full h-full ">
					<source src={Travel} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="absolute left-0 z-10 p-10 h-50" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))" }}>
					<div className="font-bold text-8xl">
						Fast Travel for,
						<br />
						Faster Businesses
					</div>
					<div className="mt-4">Simplified expense reimbursements, faster travel bookings and easy to manage compliances that's how we help you to catch your last minute flight. Create a free account and explore what Travel Wings has to offers for your business.</div>
				</div>
			</div>
			<div className="justify-center h-full align-center">
				<div className="w-full max-w-sm">
					{CheckInbox ? (
						<>
							<FiMail className="text-7xl" />
							<Logo />
							<div className="mt-6 text-5xl font-bold">Check you Inbox</div>
							<div className="mt-3 text-sm">We just dropped you a mail to verify your email address. Follow the email instructions to activate your account</div>
							<div className="flex">
								<Button onClick={() => NAVIGATE("/")} loading={Loader} className="mt-6">
									Go to Login
								</Button>
								{/* <Button variant="" onClick={resendMail} loading={Loader} className="mt-6">
									Resend Email
								</Button> */}
							</div>
						</>
					) : (
						<>
							<Logo />
							<div className="mt-6 text-5xl font-bold">All aboard !</div>
							<div className="mt-3 text-sm">Just punch in few details so that we can create your brand new account</div>
							<div className="flex flex-col mt-10">
								<Form onSubmit={validateForm} loading={Loader} className="space-y-4">
									<div className="grid grid-cols-2">
										<Input noNumbers className="mr-2" value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} required label="First name" placeholder="First Name" />
										<Input noNumbers value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} required label="Last name" placeholder="Last Name" />
									</div>
									<Input type="email" className="mr-2" value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} required label="Email" placeholder="Email" />
									<div>
										<label>Phone Number</label>
										<div className="flex gap-2 mt-2">
											<Select className="max-w-min" placeholder="Code" selected={Values.phoneCode} onChange={(phoneCode) => setValues((prev) => ({ ...prev, phoneCode }))} required label="" options={COUNTRIES} />
											<Input className="flex-1" type="number" required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} placeholder="Phone" />
										</div>
									</div>
									<Input value={Values.company} onChange={(company) => setValues((prev) => ({ ...prev, company }))} required label="Company / Organization" placeholder="Company Name" />
									<Select
										btnClass="w-full"
										selected={Values.country}
										onChange={(country) => setValues((prev) => ({ ...prev, country }))}
										required
										label="Country"
										options={[
											{ label: "United Arab Emirates", value: "AE", currency: "AED" },
											{ label: "South Africa", value: "ZA", currency: "ZAR" },
										]}
									/>
									<ErrorMsg e={Error} />
									<Button loading={Loader} type="submit" className="w-full">
										Choose Plan
									</Button>
									<div onClick={() => NAVIGATE("/")} className="mt-4 text-sm text-center cursor-pointer hover:underline">
										Already have an account ?
									</div>
								</Form>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Signup;
