import { Button } from "Components/Library";
import React from "react";

export const CheckoutPayment = ({ url, onClose, onSuccess, onFailure }) => {
	const handleLoad = (e, r) => {
		try {
			//Extract the query params from the url
			const currentUrl = e.target.contentWindow.location.href;
			const queryParams = new URLSearchParams(currentUrl.split("?")[1]);
			const recharge = queryParams.get("recharge");
			const ckoPaymentId = queryParams.get("cko-payment-id");
			if (recharge === "success") {
				onSuccess({ ckoPaymentId });
			}
		} catch (error) {
			console.error("Cross-origin iframe, can't access location");
		}
	};

	if (!url) return null;
	return (
		<div className="w-full">
			<div className="p-2">
				<Button variant="outlined" onClick={onClose}>
					Close
				</Button>
			</div>
			<div className="w-full overflow-hidden rounded-b-3xl">
				<iframe onLoad={handleLoad} src={url} title="Checkout.com Payment Gateway" className="w-full h-[90vh] -mt-[6rem]" />
			</div>
		</div>
	);
};
