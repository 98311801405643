import React, { useRef, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { Button, CheckoutPayment, ConfettiAnimation, Form, Img, Input, Modal, PeachPayments, Toast } from "../../../../Components";
import LockerImg from "../../../../Assets/Images/intros/locker.png";
import { apis, asyncPost, post } from "../../../../Utils/axios";
import useUserProfile from "../../../../Hooks/useUserProfile";
import { useSearchParams } from "react-router-dom";
import { FiCreditCard } from "react-icons/fi";

function AddMoney({ currency, onReload = () => {} }) {
	const [searchParams] = useSearchParams();
	const { profile } = useUserProfile();
	const [ShowAddMoney, setShowAddMoney] = useState(false);

	const [Loading, setLoading] = useState(false);
	const [OngoingPayment, setOngoingPayment] = useState(false);

	const [Values, setValues] = useState({});

	const [CheckoutOrder, setCheckoutOrder] = useState();
	const [PeachOrder, setPeachOrder] = useState();

	const PEACH_PG = useRef();

	const createOrder = () => {
		setLoading(true);
		post(
			apis.orderWalletRecharge,
			{
				amount: Values.amount,
				currency: currency,
			},
			(r, e) => {
				if (r) {
					setOngoingPayment(true);
					if (currency === "ZAR") {
						//Peach Payments
						const order = {
							entityId: r.entityId,
							amount: Values.amount,
							orderId: r.orderId,
							checkoutId: r.checkoutId,
							user: { name: profile ? `${profile.fName} ${profile.lName}` : "", email: profile?.email, phone: profile?.phone },
						};
						PEACH_PG.current(order);
						setPeachOrder(order);
					}
					if (currency === "AED") {
						//Checkout.com
						setCheckoutOrder({ url: r.checkoutUrl, orderId: r.orderId });
					}
				} else {
					Toast.handleError(e);
					setOngoingPayment(false);
					setLoading(false);
				}
			}
		);
	};

	const addMoney = async (txnData, orderId) => {
		const dismiss = Toast.loading("Adding money to wallet...");
		let data;
		if (currency === "ZAR") {
			const { signature, checkoutId, currency } = txnData;
			data = {
				signature,
				currency,
				checkoutId,
				orderId,
			};
		}
		if (currency === "AED") {
			const { ckoPaymentId } = txnData;
			data = {
				currency,
				checkoutId: ckoPaymentId,
				orderId: CheckoutOrder.orderId,
			};
		}
		try {
			const res = await asyncPost(apis.updatePaymentDetails, data);
			dismiss();
			if (res) onReload();
		} catch (error) {
			console.error(error);
		}
	};

	const onClose = () => {
		setShowAddMoney(false);
		setOngoingPayment(false);
		setValues({});
		setCheckoutOrder();
		setLoading(false);
	};

	return (
		<>
			<div onClick={() => setShowAddMoney(!ShowAddMoney)} className="p-4 text-white transition-all cursor-pointer bg-accent hover:bg-primary rounded-xl flex-center">
				<h1 className="gap-4 text-lg align-center">
					<FaWallet /> + Add Money
				</h1>
			</div>
			<Modal open={ShowAddMoney} onClose={!OngoingPayment ? onClose : () => {}}>
				<div className="flex items-center justify-center h-full">
					{!OngoingPayment && (
						<div className="p-6 space-y-8 text-center">
							<Img src={LockerImg} alt="Locker" className="max-w-[10rem] m-auto mb-6" />
							<div>
								<h1>Add money to wallet</h1>
								<div className="text-label">Enter the amount you want to add to your wallet</div>
							</div>
							<Form onSubmit={createOrder} className="space-y-8">
								<Input value={Values.amount} min={0} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} placeholder={`Enter amount in ${currency}`} name="amount" type="number" required />
								<div className="gap-2 flex-center">
									<Button loading={Loading} variant="primary" type="submit" className="gap-4">
										<FiCreditCard />
										Pay Now
									</Button>
									<Button variant="outlined" onClick={onClose}>
										Cancel
									</Button>
								</div>
							</Form>
						</div>
					)}
					<CheckoutPayment url={CheckoutOrder?.url} onClose={onClose} onSuccess={addMoney} />
					<PeachPayments openPg={PEACH_PG} onSuccess={addMoney} onClose={onClose} />
				</div>
			</Modal>
			<ConfettiAnimation show={searchParams.get("recharge") === "success"} />
		</>
	);
}

export default AddMoney;
