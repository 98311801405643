import { useClientSettings } from "Hooks/useClientSettings";
import React from "react";
import { FaShieldAlt } from "react-icons/fa";
import FlightCard from "./flightCard";

function HasApproval({ approval }) {
	const { currency } = useClientSettings();

	if (approval) {
		const { amount } = approval;
		return (
			<div className="grid grid-cols-5 gap-2 p-2 mt-4 bg-white border rounded-md">
				<div className="col-span-2 p-2 rounded-md bg-success fade-in-down">
					<div className="flex flex-col items-center justify-center h-full space-y-2 text-center text-accent">
						<FaShieldAlt className="text-2xl" />
						<h2 className="gap-2">
							Approved upto {currency} {amount}
						</h2>
						<div className="text-sm">You can book any flight upto this amount</div>
					</div>
				</div>
				<div className="col-span-3 bg-white border rounded-md">
					<FlightCard {...approval.request.data.flight} />
				</div>
			</div>
		);
	}

	return null;
}

export default HasApproval;
