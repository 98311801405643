import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import ALERTS from "./alerts";
import { useUserConfig } from "../../Hooks/useUserConfig";

function Alerts() {
	const { userConfig } = useUserConfig();
	const [ActiveAlerts, setActiveAlerts] = useState([]);

	useEffect(() => {
		checkAlerts();
	}, []);

	const checkAlerts = () => {
		//Check all alerts
		ALERTS.forEach((item) => {
			//If alert condition is true show the alert
			item.check(userConfig, (bool) => {
				if (bool) {
					setActiveAlerts([...ActiveAlerts, item]);
				}
			});
		});
	};

	const hideAlert = (index) => {
		ActiveAlerts.splice(index, 1);
		setActiveAlerts([...ActiveAlerts]);
	};

	return (
		<>
			<div className="fixed z-30 flex flex-col gap-2 w-80 top-2 right-2">
				{ActiveAlerts.map(({ icon, title, description }, index) => {
					return (
						<div key={index} className="relative flex items-center gap-4 p-3 border shadow-sm bg-canvas rounded-xl fade-in-right">
							<div>
								<img src={icon} alt="Bell Icon" className="w-14" />
							</div>
							<div className="col-span-3 ">
								<h5>{title}</h5>
								<div className="text-xs">{description}</div>
							</div>
							<div onClick={() => hideAlert(index)} className="absolute cursor-pointer top-2 right-2">
								<FaTimesCircle />
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Alerts;
