import React from "react";
import { Button, Input, Modal, FileInput, Toast, Form, TimePicker, DatePicker, ButtonGroup, Checkbox } from "../../../Components";
import { useState } from "react";
import { mergeDateAndTime } from "../../../Utils/utils";
import { apis, post } from "../../../Utils/axios";
import { FiUpload } from "react-icons/fi";
import { AirportSearch } from "../../../Components";
import { FaPlaneArrival, FaPlaneDeparture, FaRupeeSign } from "react-icons/fa";

function ImportFlightBooking({ requestId, onReload = () => {}, btnLabel = "Import", btnClass = "", btnVariant = "primary" }) {
	const [ImportFlight, setImportFlight] = useState();
	const initial = {
		amount: "",
		airlineCode: "",
		flightNumber: "",
		departure: "",
		departureDate: "",
		departureTime: "",
		arrival: "",
		arrivalDate: "",
		arrivalTime: "",
		type: {
			label: "Oneway",
			value: "OW",
		},
		ticket: "",
		bookingId: "",
		createExpense: true,
		requestId,
	};
	const [Values, setValues] = useState(initial);
	const [Loader, setLoader] = useState();

	const onSubmit = () => {
		if (!Values.departure) {
			return Toast.error("Please select departure airport");
		}
		if (!Values.arrival) {
			return Toast.error("Please select arrival airport");
		}
		if (!Values.departureDate) {
			return Toast.error("Please select departure date");
		}
		if (!Values.departureTime) {
			return Toast.error("Please select departure time");
		}
		if (!Values.arrivalDate) {
			return Toast.error("Please select arrival date");
		}
		if (!Values.arrivalTime) {
			return Toast.error("Please select arrival time");
		}
		setLoader(true);
		post(
			apis.importFlightBooking,
			{
				...Values,
				departureTime: mergeDateAndTime(Values.departureDate, Values.departureTime),
				arrivalTime: mergeDateAndTime(Values.arrivalDate, Values.arrivalTime),
				type: Values.type.value,
				departureCode: Values.departure.airportCode,
				arrivalCode: Values.arrival.airportCode,
			},
			(r, e) => {
				if (r) {
					Toast.success("Flight booking imported");
					setLoader();
					setImportFlight();
					setValues(initial);
					onReload();
				} else if (e) {
					console.log(e);
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setImportFlight(true)} variant={btnVariant} className={btnClass}>
				<FiUpload className="mr-2" />
				{btnLabel}
			</Button>
			<Modal
				contentClass="!max-w-[35rem]"
				open={ImportFlight}
				onClose={() => {
					setImportFlight();
					setValues(initial);
				}}
			>
				<div className="p-6">
					<h1 className="gap-2 align-center">
						<FiUpload />
						Import Flight Booking
					</h1>
					<div className="mt-2 text-label">You can import bookings done from outside and use it in Travel Wings</div>
					<Form onSubmit={onSubmit} className="flex flex-col mt-4 gap-y-4">
						{/* <ButtonGroup
							activeVariant="accent"
							className="!border-accent"
							size="xs"
							selected={Values.type}
							onClick={(type) => setValues((prev) => ({ ...prev, type }))}
							options={[
								{ label: "Oneway", value: "OW" },
								{ label: "Round", value: "RT" },
							]}
						/> */}
						<div className="grid grid-cols-2 gap-4">
							<AirportSearch selected={Values.departure} label="Travelling from" onSelect={(departure) => setValues((prev) => ({ ...prev, departure }))} placeholder="Travelling from" icon={<FaPlaneDeparture />} />
							<AirportSearch selected={Values.arrival} label="Travelling to" onSelect={(arrival) => setValues((prev) => ({ ...prev, arrival }))} placeholder="Travelling from" icon={<FaPlaneArrival />} />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input value={Values.airlineCode} onChange={(airlineCode) => setValues((prev) => ({ ...prev, airlineCode: airlineCode?.toUpperCase() }))} label="Airline code (Optional)" placeholder="Airline code" />
							<Input type="number" value={Values.flightNumber} onChange={(flightNumber) => setValues((prev) => ({ ...prev, flightNumber }))} label="Flight Number  (Optional)" placeholder="Flight Number" />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="Departure Date" setDate={(departureDate) => setValues((prev) => ({ ...prev, departureDate }))} date={Values.departureDate} />
							<TimePicker label="Departure Time" onChange={(departureTime) => setValues((prev) => ({ ...prev, departureTime }))} value={Values.departureTime} required />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<DatePicker label="Arrival Date" setDate={(arrivalDate) => setValues((prev) => ({ ...prev, arrivalDate }))} date={Values.arrivalDate} />
							<TimePicker label="Arrival Time" onChange={(arrivalTime) => setValues((prev) => ({ ...prev, arrivalTime }))} value={Values.arrivalTime} required />
						</div>
						<div className="grid grid-cols-2 gap-4">
							<Input required value={Values.bookingId} onChange={(bookingId) => setValues((prev) => ({ ...prev, bookingId }))} label="PNR Number" placeholder="Ticket Number" />
							<Input min={0} icon={<FaRupeeSign />} type="number" required value={Values.amount} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} label="Amount" placeholder="Amount" />
						</div>
						<FileInput onDelete={() => setValues((prev) => ({ ...prev, ticket: "" }))} file={Values.ticket} label="Ticket File" onUpload={(obj) => setValues((prev) => ({ ...prev, ticket: obj.file.key }))} />
						<Checkbox checked={Values.createExpense} onChange={(createExpense) => setValues((prev) => ({ ...prev, createExpense }))} label="Create an expense for this booking" />
						<Button loading={Loader} type="submit" className="w-full mt-4">
							Import
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ImportFlightBooking;
