import React, { useState } from "react";
import { StampTo24hr, StampToDate } from "../../../../Utils/utils";
import { FaClock, FaPlane, FaPlaneSlash } from "react-icons/fa";
import BookingStatus from "../Components/flightBookingStatus";
import ViewBooking from "../ViewBooking";
import moment from "moment";
import ViewApproval from "../../../Approval/ViewApproval";
import ViewRequest from "../../Request/ViewRequest";

function FlightBooking({ id, bookingId, airlineCode, flightNumber, departureCode, cabinClass, departureTime, arrivalCode, arrivalTime, type, status, approvalRequest, adminRequest, returnDate, onReload = () => {} }) {
	const [BookingId, setBookingId] = useState();
	const [ApprovalId, setApprovalId] = useState();
	const [RequestId, setRequestId] = useState();

	const duration = moment.utc(arrivalTime).diff(moment.utc(departureTime), "seconds");
	const hours = Math.floor(duration / 60 / 60);
	const minutes = (duration / 60) % 60;

	const openBooking = () => {
		if (approvalRequest) {
			setApprovalId(id);
		} else if (adminRequest) {
			setRequestId(id);
		} else {
			setBookingId(bookingId);
		}
	};

	return (
		<>
			<div onClick={openBooking} className="overflow-hidden border shadow-sm cursor-pointer rounded-xl bg-canvas min-w-min hover:bg-light">
				<div className="p-4">
					<div className="flex items-start justify-between">
						<div className="align-center">
							<img src={`https://s3.ap-south-1.amazonaws.com/public-projectpay.in/airlines/${airlineCode}.png`} alt={airlineCode} className="w-6 h-6 mr-2 rounded-full" />
							<div className="text-xs text-secondary whitespace-nowrap">
								{airlineCode}-{flightNumber}
							</div>
						</div>
						<div className="text-xs font-bold">{StampToDate(departureTime)}</div>
					</div>
					<div className="flex mt-3 gap-x-4">
						<div>
							<div className="font-bold whitespace-nowrap">{departureCode}</div>
							<div className="text-xs text-secondary">{StampTo24hr(departureTime)}</div>
						</div>
						<div className="flex-grow w-24 flex-center">
							<div className="w-full">
								<div className="align-center">
									<div className="w-2 h-2 border rounded-full border-dark" />
									<div className="flex-grow border-t border-dotted border-dark" />
									{status === "BOOKED" ? <FaPlane /> : status === "FAILED" || status === "CANCELLED" || status === "REFUNDED" ? <FaPlaneSlash /> : status === "CONFIRMING" ? <FaClock /> : <></>}
									<div className="flex-grow border-t border-dotted border-dark" />
									{type === "roundtrip" && (status === "BOOKED" ? <FaPlane className="rotate-180" /> : status === "FAILED" || status === "CANCELLED" || status === "REFUNDED" ? <FaPlaneSlash className="ml-6 rotate-180" /> : <></>)}
									<div className="flex-grow border-t border-dotted border-dark" />
									<div className="w-2 h-2 border rounded-full border-dark" />
								</div>
								<div className="mt-2 text-xs text-center whitespace-nowrap">
									{Math.abs(hours)}Hr {Math.abs(minutes)}m
								</div>
							</div>
						</div>
						<div>
							<div className="font-bold whitespace-nowrap">{arrivalCode}</div>
							<div className="text-xs text-secondary">{StampTo24hr(arrivalTime)}</div>
						</div>
					</div>
				</div>
				{status && <BookingStatus booking={{ id, cabinClass, departureCode, departureTime, arrivalCode, type, status, approvalRequest, adminRequest, returnDate }} />}
			</div>
			<ViewBooking id={BookingId} type="flight" onClose={() => setBookingId()} onReload={onReload} />
			<ViewApproval readOnly approvalId={ApprovalId} onClose={() => setApprovalId()} onReload={onReload} />
			<ViewRequest requestId={RequestId} onClose={() => setRequestId()} onReload={onReload} />
		</>
	);
}

export default FlightBooking;
