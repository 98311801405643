import React from "react";
import { Badge } from "../../../../../../../../Components";
import { FiHome, FiMapPin } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { parseTerminalName, StampTo24hr } from "../../../../../../../../Utils/utils";
import moment from "moment";

function FlightItinerary({ selectedFlight }) {
	const [searchParams] = useSearchParams();
	const from = searchParams.get("from");
	const to = searchParams.get("to");

	const { segments } = selectedFlight;
	const departure = segments[0];
	const { departureTerminal, logo, departureTime, airlineName, airlineCode, flightNumber, departureCode } = departure;
	const arrival = segments[segments.length - 1];
	const { arrivalTime } = arrival;

	return (
		<>
			<h6>Itinerary</h6>
			<div className="pl-4 mt-4">
				<div className="flex text-sm">
					<div className="border border-l" />
					<div className="relative pt-6 pl-8 align-center">
						<div className="absolute p-1 -left-3 bg-canvas">
							<FiHome />
						</div>
						<div>
							<Badge variant="">{moment.utc(moment(departureTime).subtract(1, "hours")).format("HH:mm DD MMM")}</Badge>
							<div className="mt-2 text-xs">
								Reach {departureTerminal && `${parseTerminalName(departureTerminal)}`} {from}
							</div>
						</div>
					</div>
				</div>
				{segments.map((segment, index) => (
					<div key={index} className="flex text-sm">
						<div className="border border-l" />
						<div className="relative pt-6 pl-8 align-center">
							<div className="absolute p-1 -left-6 bg-canvas">
								<img src={segment.logo} alt="airline-logo" className="w-10 h-10 mr-2 rounded-full" />
							</div>
							<div>
								<Badge variant="">{moment.utc(segment.departureTime).format("HH:mm DD MMM")}</Badge>
								<div className="mt-2 text-xs">
									{segment.airlineName} {segment.airlineCode}-{segment.flightNumber}
								</div>
								<div className="mt-1 text-xs text-secondary">
									{parseTerminalName(segment.departureTerminal)}, {segment.departureAirport}
								</div>
							</div>
						</div>
					</div>
				))}
				<div className="flex text-sm">
					<div className="border border-l" />
					<div className="relative pt-6 pl-8 align-center">
						<div className="absolute p-1 -left-3 bg-canvas">
							<FiMapPin />
						</div>
						<div>
							<Badge variant="">{moment.utc(arrivalTime).format("HH:mm DD MMM")}</Badge>
							<div className="mt-2 text-xs">
								{arrival.arrivalTerminal && `${parseTerminalName(arrival.arrivalTerminal)}`} {to}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightItinerary;
