import React from "react";
import { FiChevronDown, FiLogOut, FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SwitchRole from "./switchRole";
import { useUserConfig } from "../../Hooks/useUserConfig";
import useUserProfile from "../../Hooks/useUserProfile";
import adminIcon from "./admin.png";
import { Tooltip } from "react-tooltip";
import UpgradeToTeams from "../Intros/UpgradeToTeams";
import { Avatar } from "..";
import { useLoginHook } from "../../Hooks/useLoginHook";

function User({ mini = false }) {
	const { profile } = useUserProfile();

	const { logout } = useLoginHook();
	const NAVIGATE = useNavigate();
	const { switchRole, role, userConfig } = useUserConfig();
	const [ShowRoles, setShowRoles] = useState(false);
	const [EnableTeams, setEnableTeams] = useState();

	const options = [
		{ label: "Account Settings", route: "/app/account", icon: <FiSettings /> },
		{ label: "Sign out", onClick: logout, icon: <FiLogOut /> },
	];

	return (
		<>
			<div className="user-options">
				<div className="profile">
					<div className="relative">
						<Avatar photo={profile.photo} className="mr-2 avatar" />
						{role === "ADMIN" && (
							<div className="absolute w-4 h-4 -top-3 -left-2">
								<img src={adminIcon} className="w-full" alt="icon" />
							</div>
						)}
					</div>
					{!mini && (
						<>
							<div className="overflow-hidden">
								<div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[10rem]">
									{profile?.fName} {profile?.lName}
								</div>
								<div data-tooltip-id="user-email" data-tooltip-content={profile?.email} className="text-xs text-secondary font-light text-ellipsis whitespace-nowrap overflow-hidden max-w-[10rem]">
									{profile?.email}
								</div>
							</div>
							<div className="flex justify-end flex-1 pl-2">
								<FiChevronDown className="w-5 h-5 text-secondary" />
							</div>
						</>
					)}
				</div>
				<div className={"options"}>
					{options.map((option, index) => {
						return mini ? (
							<div data-tooltip-id="mini-item" data-tooltip-content={option.label} key={index} className="mini-item" onClick={option.route ? () => NAVIGATE(option.route) : option.onClick}>
								{option.icon}
							</div>
						) : (
							<div key={index} className="item" onClick={option.route ? () => NAVIGATE(option.route) : option.onClick}>
								{option.icon} {option.label}
							</div>
						);
					})}
				</div>
			</div>
			<Tooltip id="user-email" className="z-20 !text-xs" />
			<Tooltip id="mini-item" className="z-20 !text-xs" />
			<SwitchRole onClose={() => setShowRoles(false)} open={ShowRoles} {...{ switchRole, role, userConfig }} />
			<UpgradeToTeams open={EnableTeams} onClose={() => setEnableTeams(false)} />
		</>
	);
}

export default User;
